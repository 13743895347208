import React, { useState, useEffect } from 'react';
import { read } from '../../services/backoffice';
import Table from "./Table";
import ClipLoader from "react-spinners/ClipLoader";

const Alerts = (props) => {

    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(200%, -50%)" };


    const [array, setArray] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.value.setSidebarOption('alerts');
        read()
            .then(res => {setArray(res.data); setLoading(false) });
    },[props]);

    return (
        <main>
            <div className='main_container'>

                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Alertas</h1>
                
                {loading ?<div style={style}> <ClipLoader color={"#0069d9"} loading={loading} size={50} /></div> : <Table value={array} />}

            </div>
        </main>
    )
}

export default Alerts;