import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import UPR from '../components/upr/container';
import UAR from '../components/uar/container';
import User from "../components/user/User";
import NewUser from "../components/newUser/NewUser";
import Alerts from "../components/alerts/Alerts";
import Update from "../components/updateSn/update";


const LoginRoute = (props) => {
    return (
        <Router>
            <div className="container1">
                <NavBar value={props.value} />
                <Switch>
                    <Route path="/" exact>
                        <UPR value={props.value} />
                    </Route>
                    <Route path="/uar">
                        <UAR value={props.value} />
                    </Route>
                    <Route path="/perfil">
                        <User value={props.value} />
                    </Route>
                    <Route path="/utilizadores">
                        <NewUser value={props.value} />
                    </Route>
                    <Route path="/alertas">
                        <Alerts value={props.value} />
                    </Route>
                    <Route path="/alterar" exact>
                        <Update value={props.value} />
                    </Route>
                    <Redirect to="/" />
                </Switch>
                <Sidebar value={props.value} />
            </div>
        </Router >);
}

export default LoginRoute;