import React, { useEffect, useState } from 'react';

const Update = (props) => {
    useEffect(() => {
        props.value.setSidebarOption('update');
    }, [props]);

    const [form, setForm] = useState({
        oldSN: "",
        newSN: "",
        name: ""
    });

    // Mudar valor dos atributos
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <main>
            <div className='main_container'>
                <h1 className='h1 mt-3 ml-3 font-weight-normal'>Alterar SN</h1>
                <div className='card mt-3 ml-3 mr-3'>
                    <div className='card-body'>
                        <form >
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="oldSn">Antigo SN</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="oldSn"
                                    id="oldSn"
                                    onChange={handleChange}
                                    value={form.oldSn}
                                />
                            </div>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="newSN">Novo SN</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="newSN"
                                    id="newSN"
                                    onChange={handleChange}
                                    value={form.newSN}
                                />
                            </div>
                            <div className="form-group mt-2 ml-2 mr-2">
                                <label htmlFor="name">Nome</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={handleChange}
                                    value={form.name}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mt-2 ml-2 mr-2">Alterar</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )

}

export default Update;