import React, { useState } from 'react';
import { login } from "../../services/backoffice";
import Cookies from 'universal-cookie';


const Login = (props) => {
    const cookies = new Cookies();
    const [error, setError] = useState("");

    const [form, setForm] = useState({ email: "", password: "" });

    // Mudar valor dos atributos
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    // Submit do form
    const handleSubmit = (event) => {
        event.preventDefault();
        login(form)
            .then(data => {
                if (data.success) {
                    cookies.set('token', data.jwt);
                    cookies.set('email', data.email);
                    window.location.reload(false);
                } else {
                    errors(data)
                }
            })

    };

    // Erros
    const errors = (erro) => {
        if (!erro.success) {
            if (erro.error !== 1) {
                setError("Credenciais não válidas");
            } else {
                setError("");
            }
        } else {
            setError("");
        }
    }

    return (
        <div className="main vh-100">
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="">
                        <div className="login align-items-center">
                            <h1 className="text-center">Procat</h1>
                            <hr></hr>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        className={error === "" ? "form-control" : "is-invalid form-control"}
                                        type="text"
                                        name="email"
                                        id="email"
                                        onChange={handleChange}
                                        value={form.email}
                                    />
                                    <small className="invalid-feedback">{error}</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Password</label>
                                    <input
                                        className={error === "" ? "form-control" : "is-invalid form-control"}
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={handleChange}
                                        value={form.password}
                                    />
                                    <small className="invalid-feedback">{error}</small>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">Iniciar Sessão</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;