import logo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom';

import { FaKey, FaTimes } from "react-icons/fa";
import { MdEmail } from "react-icons/md";


const Sidebar = (props) => {
    return (
        <div className={props.value.sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
            <div className="sidebar_title">
                <div className="sidebar_image">
                    <img src={logo} alt="logo" />
                    <h1 className="font-weight-bold ml-3">Dashboard</h1>
                </div>
                <FaTimes className="nav_icon" onClick={() => props.value.setSidebarOpen(false)} />
            </div>
            <hr></hr>
            <div className="sidebar_menu">
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'upr' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <FaKey />
                        <span className="ml-2">UPC</span>
                    </div>
                </Link>
                <Link to='/uar' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'uar' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <FaKey />
                        <span className="ml-2">UAR</span>

                    </div>
                </Link>
                <Link to='/alterar' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'update' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <MdEmail />
                        <span className="ml-2">Alterar SN</span>
                    </div>
                </Link>
                <Link to='/alertas' style={{ textDecoration: 'none' }}>
                    <div className={props.value.sidebarOption === 'alerts' ? "sidebar_link active_menu_link" : "sidebar_link"}>
                        <MdEmail />
                        <span className="ml-2">Alertas</span>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default Sidebar;