import axios from "axios";
import Cookies from 'universal-cookie';
import { logout, updateToken } from "./backoffice";

const SERVER_URL = "http://backoffice.cires2biot.pt";
const cookies = new Cookies();


export async function read() {
    try {
        const res = await axios
            .get(`${SERVER_URL}/api/upr_status/readLastUse.php`, {
                headers: {
                    'Authorization': cookies.get('token')
                }
            });
        if (res.data.jwt === false) {
            logout();
        } else {
            updateToken(res.data.jwt);
        }
        return res.data;
    } catch (error) {
        return console.log(error);
    }
}